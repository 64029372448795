<template>
  <section>
    <h1>{{ $t('editor_title') }}</h1>
    <div class="preview-display">
      <div class="render">
        <canvas id="filtered-preview"></canvas>
        <!--        <img
                  :src="`${currentHost}${this.$store.state.imagePath}`"
                  alt="" ref="image" crossorigin="anonymous">-->
      </div>
    </div>
    <div class=" message-controls">
      <div class="controls font-color">
        <h4>{{ $t('editor_option1_image_title') }}</h4>
        <div class="controls-container">
          <ul class="text-color-wrap">
            <!-- eslint-disable max-len -->
            <li ref="colorOption" v-for="(item, i) in optionsTextColors" :key="i"
                :style="`background: linear-gradient(to right, ${item.color_1} 50%, ${item.color_2} 50%);`"
                @click="updateOptions(i, 'filter')">
            </li>
            <!-- eslint-enable max-len -->
          </ul>
        </div>
      </div>
    </div>
    <MButton :label="$t('btn_uploadImage')" @click.native.once="saveFilterImage()"></MButton>
  </section>
</template>

<script>
import MButton from '@/components/MButton.vue';
import axios from 'axios';

export default {
  name: 'EditorImage',
  components: {
    MButton,
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      optionsTextColors: [
        /* {
          id: 'none',
          name: 'none',
          color_1: '#fff',
          color_2: '#fff',
        }, */
        {
          id: 'greyscale',
          name: 'grey',
          color_1: '#fff',
          color_2: '#000',
        },
        {
          id: 'blues',
          color_1: '#E20659',
          color_2: '#BCDCB2',
        },
        {
          id: 'neue',
          color_1: '#00A23C',
          color_2: '#FFE60B',
        },
        {
          id: 'lix',
          color_1: '#2D489C',
          color_2: '#EA630D',
        },
        {
          id: 'ryo',
          color_1: '#E20659',
          color_2: '#FFE60B',
        },
      ],
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    currentHost() {
      return process.env.VUE_APP_API_URL;
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
    this.$store.state.showBackButton = true;
    this.initialEditorMode();
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    initialEditorMode() {
      const canvas = document.getElementById('filtered-preview');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.crossOrigin = '';
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;

        for (let i = 0; i < pixels.length; i += 4) {
          const count = pixels[i] + pixels[i + 1] + pixels[i + 2];
          let colour = 0;
          if (count > 383) colour = 255;

          pixels[i] = colour;
          pixels[i + 1] = colour;
          pixels[i + 2] = colour;
          pixels[i + 3] = 255;
        }

        ctx.putImageData(imageData, 0, 0);
      };

      this.duoToneHandler(0);

      img.src = `${this.currentHost}${this.$store.state.imagePath}`;
    },
    // eslint-disable-next-line no-unused-vars
    duoToneHandler(id) {
      const canvas = document.getElementById('filtered-preview');
      const ctx = canvas.getContext('2d');

      console.log(id);

      const img = new Image();
      img.crossOrigin = '';
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;

        // MAKE BLACK AND WHITE
        for (let i = 0; i < pixels.length; i += 4) {
          const count = pixels[i] + pixels[i + 1] + pixels[i + 2];
          let colour = 0;
          if (count > 383) colour = 255;

          pixels[i] = colour;
          pixels[i + 1] = colour;
          pixels[i + 2] = colour;
          pixels[i + 3] = 255;
        }

        if (this.optionsTextColors[id].name !== 'grey') {
          // MAKE GRADIENT
          const rgb1 = this.hexToRgb(this.optionsTextColors[id].color_1);
          const rgb2 = this.hexToRgb(this.optionsTextColors[id].color_2);
          const gradient = [];
          for (let i = 0; i < (256 * 4); i += 4) {
            gradient[i] = ((256 - (i / 4)) * rgb1.r + (i / 4) * rgb2.r) / 256;
            gradient[i + 1] = ((256 - (i / 4)) * rgb1.g + (i / 4) * rgb2.g) / 256;
            gradient[i + 2] = ((256 - (i / 4)) * rgb1.b + (i / 4) * rgb2.b) / 256;
            gradient[i + 3] = 255;
          }

          // ASSIGN TO CANVAS
          const d = pixels;
          for (let i = 0; i < d.length; i += 4) {
            d[i] = gradient[d[i] * 4];
            d[i + 1] = gradient[d[i + 1] * 4 + 1];
            d[i + 2] = gradient[d[i + 2] * 4 + 2];
          }
        }

        ctx.putImageData(imageData, 0, 0);
      };
      img.src = `${this.currentHost}${this.$store.state.imagePath}`;
    },
    hexToRgb(value) {
      const hex = value.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return {
        r,
        g,
        b,
      };
    },
    updateOptions(i, type) {
      switch (type) {
        case 'filter':
          this.colorID = i;
          this.$refs.colorOption.forEach((el) => {
            el.classList.remove('active');
          });
          this.$refs.colorOption[i].classList.add('active');

          if (i === 0) {
            this.initialEditorMode();
          } else {
            this.duoToneHandler(this.colorID);
          }
          break;
        default:
      }
    },
    saveFilterImage() {
      const canvas = document.querySelector('.preview-display .render canvas');
      canvas.toBlob((blob) => {
        const file = new File([blob], 'test.png', {
          type: 'image/png',
          lastModified: Date.now(),
        });
        const formData = new FormData();
        formData.append('file', file);

        axios.post(`${process.env.VUE_APP_API_URL}/purify/uploadImage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            console.log('response from Cropper');
            console.log(response.data.path);
            this.pathToImage = response.data.path;
            this.$store.state.imagePath = this.pathToImage;
            this.submitEntry();
          });
      });
    },
    submitEntry() {
      const formData = new FormData();

      formData.append('type', 'image');
      formData.append('message', this.$store.state.imagePath);
      formData.append('broadcasted', 0);
      formData.append('entryTime', Date.now());
      formData.append('format', null);
      formData.append('uuid', document.body.getAttribute('clientid')
        .toString());

      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}/messages/image`,
        headers: { 'Content-Type': 'application/json' },
        data: formData,
      };

      axios.request(options)
        .then((response) => {
          console.log(response.data);
          // this.$store.state.messageFormat = this.messageFormat;
          this.$router.push({ name: 'result' });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
section {
  text-align: left;
  padding: 15px;
  // height: 100%;

  h1 {
    margin-top: 15px;
    margin-bottom: 14px;
    text-align: left;
  }
}

body[data-daymode='true'] {
  .preview-display {
    background-color: #fff;
  }
}

body[data-daymode='false'] {
  .preview-display {
    background-color: #000;
  }
}

.preview-display {
  font-family: 'Bourton Base', sans-serif;
  width: calc(100%);
  // aspect-ratio: 312 / 208;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 24px;
  word-break: break-word;
  overflow: hidden;

  .render {
    width: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  canvas {
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
  }

  span {
    margin: 16px 0 0 0;
    display: block;
    font-size: 12px;
  }

  > div {
    display: block;
    position: relative;
  }
}

.message-controls {
  margin-bottom: 36px;
}

::-webkit-scrollbar {
  display: none;
}

.controls {
  text-align: left;

  h4 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 7px;
  }

  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 15px;
    margin: 0 -15px 15px;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-top: 2px;
    padding-bottom: 10px;

    li {
      width: 47px;
      height: 47px;
      display: flex;
      border-radius: 100%;
      margin-right: 16px;
      position: relative;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      outline: 1px solid #ccc;

      &.active {
        &:before {
          content: '';
          width: 17px;
          height: 12px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='12' viewBox='0 0 17.4 12'%3E%3Cpath id='Checkbox' d='M6.9,12,0,5.1,1.4,3.7,6.9,9.1,16,0l1.4,1.4Z' fill='%23fff'/%3E%3C/svg%3E%0A");
          display: block;
          color: white;
          position: absolute;
          font-size: 1.6rem;
          z-index: 2000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          border-radius: 100%;
        }
      }
    }
  }
}
</style>
